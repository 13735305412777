import React, { Component } from 'react';

import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { request } from '../../Instance';

class Auth extends Component {

    loadContract = () => {
        request('get', `/protocol/${this.props.match.params.protocol}`, {}, false)
        .then(response => {
            this.props.setUser(response.data);
        })
        .catch(error => {
            if(error.response.status === 401){
                this.props.history.push('/invalido');
            }
        })
    }

    componentDidMount = () => {
        this.loadContract();
    }

    render() {
        return (<div>{this.props.children}</div>);
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUser: data => {dispatch({type: 'SET_CONTRACT', payload: data})},
    }
}

const mapStateToProps = state => {
    return {
        contract: state.contract
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Auth));