const defaultState = {
    loaded: false,
    data: []
}
const contract = (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_CONTRACT':
        return {
            loaded: true, 
            data: action.payload
        }
        break;
       
        default:
        return state
    }
}

export default contract;