import React, { Component } from 'react';
import { Button, Typography, Alert } from 'antd';
const { Title } = Typography;

export default class Step1 extends Component {
  render() {
    const { contract } = this.props;
    return (
        <div>
            <center>
              {contract.data.status === 'Documentação Pendente' && (
                <Title level={3}>Olá {contract.data.customer.name}! <br /><small>Para dar prosseguimento com seu pedido de empréstimo, tenha em mãos seus documentos pessoais.</small></Title>
              )}
              {contract.data.status === 'Documentação Recusada' && (
                <div>
                  <Title level={3}>Olá {contract.data.customer.name}! <br />
                    <small>Um ou mais documentos não foram aprovados. Reenvie novamente os documentos solicitados para dar continuidade no seu pedido de empréstimo.</small>
                    <br />
                  </Title>
                  {contract.data.refusal_message_doc && (
                  <Alert
                    description={contract.data.refusal_message_doc}
                    type="info"
                  />
                  )}
                  <br />
                 </div>
               )}
            </center>
            <Button 
                type="primary"
                size="large" 
                onClick={this.props.nextStep}
                block>Continuar
            </Button>
        </div>
    )
  }
}
