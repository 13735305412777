import React, { Component } from 'react';
import { Upload, Icon, message } from 'antd';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

// function beforeUpload(file) {
//   const isJPG = file.type === 'image/jpeg';
//   if (!isJPG) {
//     message.error('You can only upload JPG file!');
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2;
//   if (!isLt2M) {
//     message.error('Image must smaller than 2MB!');
//   }
//   return isJPG && isLt2M;
// }

class UploadSignature extends React.Component {
  constructor(props){
    super(props);

    const { attachments } = props.contract.data;

    const attachment = attachments.filter(row => row.file_type===`Página ${props.page}`);

    this.state = {
      loading: false,
      imageUrl: attachment.length ? `${process.env.REACT_APP_API}/storage/signatures/${attachment[0].file}` : null
    }
  }

  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => this.setState({
        imageUrl,
        loading: false,
      }));

      this.props.updateContract(info.file.response.data);
    }
  }

  render() {
    const { page } = this.props;
    
    const imageUrl = this.state.imageUrl;
    return (
      <Upload
        name="file"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action={`${process.env.REACT_APP_API}/protocol/${this.props.match.params.protocol}/contract`}
        // beforeUpload={beforeUpload}
        onChange={this.handleChange}
        data={{file_type: `Página ${page}`}}
      >
        {imageUrl ? <img src={imageUrl} alt={`Página ${page}`} width="100%" /> : <div>
        <Icon type={this.state.loading ? 'loading' : 'upload'} />
        <div className="ant-upload-text">Página {page}</div>
      </div>}
      </Upload>
    );
  }
}

export default UploadSignature;