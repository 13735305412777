import React, { Component } from 'react';
import { Button, Typography, Alert } from 'antd';
const { Title } = Typography;

export default class Recused extends Component {
  render() {
    const { contract } = this.props;
    return (
        <div>
            <center>
                <div>
                  <Title level={3}>Olá {contract.data.customer.name}! <br />
                    <small>Uma ou mais cópias do contrato não foram aprovados. Reenvie novamente as páginas solicitadas para dar continuidade no seu pedido de empréstimo.</small>
                    <br />
                  </Title>
                  {contract.data.refusal_message_doc && (
                  <Alert
                    description={contract.data.refusal_message_doc}
                    type="info"
                  />
                  )}
                  <br />
                 </div>
            </center>
            <Button 
                type="primary"
                size="large" 
                onClick={this.props.nextStep}
                block>Continuar
            </Button>
        </div>
    )
  }
}
