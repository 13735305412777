import React, { Component } from 'react';

import Layout from '../components/UI/Layout/Layout';
import ContractDetails from '../components/Contract/ContractDetails';

class ScreensContract extends Component {

    render() {
        return (
            <Layout>
                <ContractDetails/>
            </Layout>
        );
    }
}

export default ScreensContract;