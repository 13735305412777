import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Steps, Row, Col }  from 'antd';
import { withRouter } from 'react-router';

import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';
import Recused from './Steps/Recused';
import Finish from './Steps/Finish';
import NoShow from './Steps/NoShow';

const Step = Steps.Step;

class ContractDetails extends Component {

  constructor(props){
    super(props);

    let step = 1;
    let stepMain = 0;

    if(props.contract.data.status === 'Aprovação Pendente' || props.contract.data.status === 'Contrato Pendente'){
      step = 7;
    }

    if(props.contract.data.status === 'Aceite Pendente'){
      step = 8;
      stepMain = 1;
    }

    if(props.contract.data.status === 'Assinatura Pendente'){
      step = 9;
      stepMain = 2;
    }

    if(props.contract.data.status === 'Aguardando Aprovação'){
      step = 10;
      stepMain = 3;
    }

    if(props.contract.data.status === 'Cópia Recusada'){
      step = 11;
      stepMain = 2;
    }

    if(props.contract.data.status === 'Aprovado' || props.contract.data.status === 'Cancelado'){
      step = 11;
      stepMain = null;
    }

    this.state = {
      step: step,
      stepMain: stepMain
    }
  }

  nextStep = (step = null) => {

    let stepMain = this.state.stepMain;

    if(this.props.contract.data.status === 'Assinatura Pendente' || this.props.contract.data.status == 'Cópia Recusada'){
      stepMain = 2;
    }

    if(this.props.contract.data.status === 'Aguardando Aprovação'){
      stepMain = 3;
    }

    step = Number.isInteger(step)?step:this.state.step+1;

    this.setState({step: step, stepMain: stepMain});

  }

  render() {
    const { contract } = this.props;
    const { step, stepMain } = this.state;

    return (
      <div>
        {stepMain !== null && (
          <Row style={{ 
          margin: `0px -16px`, 
          padding: '15px', 
          background: '#fff', 
          marginBottom: `10px` }}>
            <Col xs={{ span: 24 }} lg={{ span: 16, offset: 4 }}>
              <Steps current={stepMain}>
                <Step title="Envio de Documentos" />
                <Step title="Contrato" />
                <Step title="Assinatura do Contrato"/>
              </Steps>
            </Col>
          </Row>
        )}
        <Row>
          <Col style={{ 
            padding: '15px', 
            background: '#fff'}}
            xs={{ span: 24 }} 
            lg={{ span: 16, offset: 4 }}>
            {step===1&&(<Step1 nextStep={this.nextStep} {...this.props}/>)}
            {step===2&&(
            <Step2 
              nextStep={this.nextStep} 
              title="1. Selfie com o Documento"
              file="Selfie"
              {...this.props}/>
            )}
            {step===3&&(
              <Step2 
                nextStep={this.nextStep}
                title="2. Documento (Frente)"
                file="Doc Frente"
                {...this.props}
              />
            )}
            {step===4&&(
              <Step2 
                nextStep={this.nextStep}
                title="3. Documento (Verso)"
                file="Doc Verso"
                {...this.props}
              />
            )}
            {step===5&&(
              <Step2 
                nextStep={this.nextStep}
                title="4. Comprovante de endereço"
                file="Comprovante Endereço"
                {...this.props}
              />
            )}
            {step===6&&(
              <Step2 
                nextStep={this.nextStep}
                title="5. Dados bancários"
                file="Cartão"
                {...this.props}
              />
            )}
            {step===7&&(
              <Step3 
                nextStep={this.nextStep}
                {...this.props}
              />
            )}
            {step===8 &&(
              <Step4 
                nextStep={this.nextStep}
                {...this.props}
              />
            )}
            {step===9 &&(
              <Step5
                nextStep={() => this.nextStep(10)}
                {...this.props}
              />
            )}
            {step===10 &&(
              <Finish
                nextStep={this.nextStep}
                {...this.props}
              />
            )}
            {step===11 &&(
              <Recused
                nextStep={() => this.nextStep(8)}
                {...this.props}
              />
            )}
            {step===112 &&(
              <NoShow
                {...this.props}
              />
            )}
          </Col>
        </Row>
      </div>
      )
  }
}

const mapStateToProps = state => ({
    contract: state.contract
})

const mapDispatchToProps = dispatch => ({
    updateContract: data => {dispatch({type: 'SET_CONTRACT', payload: data})}
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractDetails));