import React, { Component } from 'react';
import { Typography } from 'antd';
import warning from './warning.svg';
const { Title } = Typography;

export default class NoShow extends Component {
  render() {
    return (
        <div>
            <center>
                <Title level={3}>Não encontrado!<br /><small>Este protocolo já foi encerrado ou foi cancelado.</small></Title>
                <img src={warning} height="200px"/>
            </center>
        </div>
    )
  }
}