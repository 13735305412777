import React, { Component } from 'react';
import { Button, Typography } from 'antd';
import { request } from '../../Instance';
const { Title } = Typography;

export default class Step1 extends Component {

    state = {
        loading: false
    }

    openContract = () => {
        window.open(`${process.env.REACT_APP_API}/storage/contracts/${this.props.contract.data.file}`, '_blank');
    }

    accept = () => {
        this.setState({loading: true});
        request('put', `/protocol/${this.props.contract.data.protocol}/accept`, {}, false)
        .then(response => {
            this.setState({loading: false});
            this.props.nextStep();
        })
        .catch(response => {
            this.setState({loading: false});
        });
    }

  render() {
    const { loading } = this.state;
    const { contract } = this.props;

    return (
        <div>
            <center>
                <Title level={3}>Bem-vindo de volta {contract.data.customer.name}! <br /><small>Agora precisamos que você imprima, assine e envie seu contrato assinado, para darmos continudade no seu pedido de empréstimo.</small></Title>
            </center>
            <Button 
                size="large" 
                onClick={this.openContract}
                type="primary"
                className="mb-10"
                block>Visualizar Contrato
            </Button>
            <Button 
                size="large" 
                onClick={this.accept}
                className="btn-success"
                loading={loading}
                block>Aceito as condições do contrato Nº {contract.data.protocol}
            </Button>
        </div>
    )
  }
}
