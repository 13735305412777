import React, { Component } from 'react';
import { Button, Typography, Upload, Icon, message, Row, Col } from 'antd';

import file_self from './self.png';
import file_doc_front from './rg_front.jpg';
import file_doc_back from './rg_back.jpg';
import address from './address.svg';
import card from './card.png';

const { Title } = Typography;

export default class Step1 extends Component {
      
  state = {
      loading: false,
  };

  componentDidMount = () => {
    const { attachments } = this.props.contract.data;
    const attachment = attachments.filter(row => row.file_type===this.props.file);

    if(attachment.length > 0){
      this.props.nextStep();
    }
  }
    
  render() {
    const { contract, updateContract, nextStep } = this.props;

    const attachment = contract.data.attachments.filter(row => row.file_type===this.props.file);

    const props = {
        name: 'file',
        data: { type: this.props.file },
        action: `${process.env.REACT_APP_API}/protocol/${this.props.match.params.protocol}/doc`,
        listType: 'picture',
        className: 'upload-list-inline',
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} foto enviada com sucesso!`);
            updateContract(info.file.response.data);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} o envio falhou.`);
          }
        },
      };

    return (
        <div>
            <center>
                <Title level={3}>{this.props.title}</Title>
                {this.props.file === 'Selfie' && (
                  <img src={file_self} height="200px"/>
                )}
                {this.props.file === 'Doc Frente' && (
                  <img src={file_doc_front} height="200px"/>
                )}
                {this.props.file === 'Doc Verso' && (
                  <img src={file_doc_back} height="200px"/>
                )}
                {this.props.file === 'Comprovante Endereço' && (
                  <img src={address} height="200px"/>
                )}
                {this.props.file === 'Cartão' && (
                  <img src={card} height="200px"/>
                )}
            </center>
            <br/>
            <Row>
              <Col span={24}>
                <Upload {...props}>
                  <Button type="primary" size="large" block>
                      <Icon type="upload" /> Tirar Foto
                  </Button>
              </Upload>
              </Col>
              {attachment.length > 0 && (
              <Col span={24} className="mt-10">
                <Button type="default" className="btn-success" size="large" onClick={nextStep} block>
                  Continuar
                </Button>
              </Col>
              )}
            </Row>
        </div>
    )
  }
}
