import React, { Component } from 'react';
import { Button, Typography, Divider } from 'antd';
import Upload from './UploadSignature/Upload';
const { Title } = Typography;

export default class Step1 extends Component {

    state = {
        loading: false
    }

    openContract = () => {
        window.open(`${process.env.REACT_APP_API}/storage/contracts/${this.props.contract.data.file}`, '_blank');
    }

  render() {
    const { loading } = this.state;
    const { contract } = this.props;

    const createUploadList = () => {
        let upload = [];
        for(let i=1; i<=contract.data.pages; i++){
            upload.push(<Upload page={i} {...this.props}/>)
        }

        return upload;
    }

    return (
        <div>
            <center>
                <Title level={3}>Falta pouco {contract.data.customer.name}! <br /><small>Agora precisamos que você envie seu contrato assinado, para darmos continudade na sua solicitação de empréstimo.</small></Title>
            </center>
            <Button 
                icon="eye"
                size="large" 
                onClick={this.openContract}
                type="default"
                className="mb-10"
                block>Visualizar Contrato
            </Button>
            <br />
            <center>
                {createUploadList()}
            </center>
            <Button 
                size="large" 
                onClick={this.props.nextStep}
                className="btn-success"
                loading={loading}
                disabled={(contract.data.attachments.filter(r => r.file_type.includes('Página')).length < contract.data.pages)}
                block>Enviar
            </Button>
        </div>
    )
  }
}
