import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ScreeesNoMatch from './screens/NoMatch';
import ScreensContract from './screens/Contract';

const ScreensRoot = () => (
  <Router>
    <Switch>
      <Route path="/:protocol" component={ScreensContract} exact/>

      {/* Rota 404 */}
      <Route component={ScreeesNoMatch}/>
    </Switch>
  </Router>
);

export default ScreensRoot;