import React, { Component } from 'react';
import { Typography } from 'antd';
import mail from './mail.svg';
const { Title } = Typography;

export default class Step3 extends Component {
  render() {
    const { contract } = this.props;
    return (
        <div>
            <center>
                <Title level={3}>Falta pouco {contract.data.customer.name}! <br /><small>Já recebemos seus documentos e eles passarão por uma análise. Mas fique tranquilo, essa análise não demora mais que 48 horas e você será notificado por e-mail e telefone.</small></Title>
                <img src={mail} height="200px"/>
            </center>
        </div>
    )
  }
}