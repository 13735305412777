import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Layout, Row, Col, Spin, Icon } from 'antd';
import Auth from './Auth';

import logo2 from './images/logo.png';

import './layout.css';

const { Header, Content, Footer } = Layout;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

class Main extends React.Component {

  render() {
    const { contract } = this.props;

    return (
      <Auth>
        <Spin 
          spinning={(!contract.loaded)} 
          tip="Carregando..." 
          indicator={antIcon}>
        <Layout style={{ minHeight: '100vh' }}>
          <Layout>
            <Header style={{
              background: '#283895', 
              padding: `7px`,
              boxShadow: `0px 1px 8px 0px rgba(0,0,0,0.1)`,
              zIndex: `989`,
              height: `100px`
              }}>
              <Row>
                <Col span={16} offset={4}>
                  <img src={logo2} alt="Solução - Crédito Online" style={{height: '45px'}}/>
                </Col>
              </Row>
            </Header>
            {contract.loaded ? (
            <Content style={{ margin: `0px 16px` }}>
                {this.props.children}
            </Content>
            ):
            <Content style={{ margin: `0px 16px` }}>
            </Content>
            }
            <Footer style={{ textAlign: 'center' }}>
              Solução - Crédito Online ©2019
            </Footer>
          </Layout>
        </Layout>
        </Spin>
      </Auth>
    );
  }
}

const mapDispatchToProps = (state) => ({
  contract: state.contract
});

export default connect(mapDispatchToProps)(withRouter(Main));